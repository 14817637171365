// in src/Dashboard.js
import * as React from 'react';
import { Box, Card, CardContent, CardHeader, Tab, Tabs, Tooltip } from '@material-ui/core';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import TrendingDownIcon from '@material-ui/icons/TrendingDown';
import { Error, Loading, ShowButton, useQuery, useQueryWithStore } from 'react-admin';
import { Link } from 'react-router-dom';
import OrderTable from './OrderTable';
import Config from 'Config';
import myAxios from 'helpers/axios';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

const IntegratedDashboard = () => {
  const [trendIndex, setTrendIndex] = React.useState({
    orderTotal: 0,
    orderSum: 0,
    jobSum: 0,
    profit: 0,
    pOrderTotal: 0,
    pJobSum: 0,
    pOrderSum: 0,
    pProfit: 0,
  });
  const [tabValue, setTabValue] = React.useState(0);
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const {
    data: pickedUpOrder,
    loading: pickedUpOrderLoading,
    error: pickedUpOrderError,
  } = useQuery({
    type: 'getList',
    resource: 'admin/dashboard/picked-up',
    payload: {
      pagination: { page: 1, perPage: 50 },
      sort: { field: 'created_at', order: 'asc' },
      filter: {},
    },
  });

  const {
    data: pendingPickOrder,
    loading: pendingPickOrderLoading,
    error: pendingPickOrderError,
  } = useQueryWithStore({
    type: 'getList',
    resource: 'admin/dashboard/pending-pickup-order',
    payload: {
      pagination: { page: 1, perPage: 50 },
      sort: { field: 'created_at', order: 'asc' },
      filter: {},
    },
  });

  const {
    data: waitingNroOrder,
    loading: waitingNroOrderLoading,
    error: waitingNroOrderError,
  } = useQuery({
    type: 'getList',
    resource: 'admin/dashboard/waiting-nro',
    payload: {
      pagination: { page: 1, perPage: 50 },
      sort: { field: 'created_at', order: 'asc' },
      filter: {},
    },
  });

  const {
    data: completeNotVerifyOrder,
    loading: completeNotVerifyOrderLoading,
    error: completeNotVerifyOrderError,
  } = useQuery({
    type: 'getList',
    resource: 'admin/dashboard/completed-not-verify',
    payload: {
      pagination: { page: 1, perPage: 50 },
      sort: { field: 'created_at', order: 'asc' },
      filter: {},
    },
  });

  const {
    data: inProgressOrder,
    loading: inProgressOrderLoading,
    error: inProgressOrderError,
  } = useQuery({
    type: 'getList',
    resource: 'admin/dashboard/in-progress',
    payload: {
      pagination: { page: 1, perPage: 50 },
      sort: { field: 'created_at', order: 'asc' },
      filter: {},
    },
  });

  const fetchTrendIndex = React.useCallback(async () => {
    return myAxios.get(myAxios.addApiUrl('/admin/dashboard/trend-index')).then(async (res) => {
      setTrendIndex(res.data);
    });
  }, []);

  const getPercentageChange = React.useMemo(() => {
    return (newValue, originValue) => {
      return ((newValue - originValue) / originValue) * 100;
    };
  }, []);

  const TrendingLabel = ({ newValue, originValue }) => {
    const percentageChange = getPercentageChange(newValue, originValue);
    if (!newValue || !originValue || originValue === 0) return <p>載入中</p>;
    if (percentageChange > 0) {
      return (
        <Tooltip title={originValue}>
          <p className="cardTrend raise">
            <TrendingUpIcon />
            上升 {Math.round(percentageChange)}% <span>自上月起</span>
          </p>
        </Tooltip>
      );
    } else {
      return (
        <Tooltip title={originValue}>
          <p className="cardTrend down">
            <TrendingDownIcon />
            下降 {Math.round(percentageChange)}% <span>自上月起</span>
          </p>
        </Tooltip>
      );
    }
  };

  React.useEffect(() => {
    fetchTrendIndex();
  }, [fetchTrendIndex]);

  return (
    <div>
      <div className="cardContainer">
        <Card className="card">
          <p className="cardTitle">本月收入</p>
          <p className="cardFigure">${trendIndex?.orderSum}</p>
          <TrendingLabel newValue={trendIndex?.orderSum} originValue={trendIndex?.pOrderSum} />
        </Card>
        <Card className="card">
          <p className="cardTitle">本月訂單</p>
          <p className="cardFigure">{trendIndex?.orderTotal}單</p>
          <TrendingLabel newValue={trendIndex?.orderTotal} originValue={trendIndex?.pOrderTotal} />
        </Card>
        <Card className="card">
          <p className="cardTitle">本月師傅開支</p>
          <p className="cardFigure">${trendIndex?.jobSum}</p>
          <TrendingLabel newValue={trendIndex?.jobSum} originValue={trendIndex?.pJobSum} />
        </Card>
        <Card className="card">
          <p className="cardTitle">本月利潤</p>
          <p className="cardFigure">${trendIndex?.profit}</p>
          <TrendingLabel newValue={trendIndex?.profit} originValue={trendIndex?.pProfit} />
        </Card>
      </div>
      <div className="tabContainer">
        <Tabs value={tabValue} onChange={handleTabChange} variant="scrollable" scrollButtons="auto">
          <Tab className="tabLabel" label="訂單資訊" />
          <Tab className="tabLabel" label={`未接單`} />
          <Tab className="tabLabel" label={`未出發`} />
          <Tab className="tabLabel" label={`待報價`} />
          <Tab className="tabLabel" label={`完成未驗證`} />
          <Tab className="tabLabel" label={`進行中`} />
        </Tabs>

        <Box>
          <CustomTabPanel value={tabValue} index={0}>
            <Box className="orderInfoCardContainer">
              <Card className="orderInfoCard c1" onClick={() => setTabValue(1)}>
                <p className="orderInfoCard-figure">{pendingPickOrder ? pendingPickOrder.length : 0}</p>
                <p className="orderInfoCard-heading">未接訂單</p>
              </Card>
              <Card className="orderInfoCard c2" onClick={() => setTabValue(2)}>
                <p className="orderInfoCard-figure">{pickedUpOrder ? pickedUpOrder.length : 0}</p>
                <p className="orderInfoCard-heading">未出發</p>
              </Card>
              <Card className="orderInfoCard c3" onClick={() => setTabValue(3)}>
                <p className="orderInfoCard-figure">{waitingNroOrder ? waitingNroOrder.length : 0}</p>
                <p className="orderInfoCard-heading">待報價</p>
              </Card>
              <Card className="orderInfoCard c4" onClick={() => setTabValue(4)}>
                <p className="orderInfoCard-figure">{completeNotVerifyOrder ? completeNotVerifyOrder.length : 0}</p>
                <p className="orderInfoCard-heading">完成未驗證</p>
              </Card>
              <Card className="orderInfoCard c5" onClick={() => setTabValue(5)}>
                <p className="orderInfoCard-figure">{inProgressOrder ? inProgressOrder.length : 0}</p>
                <p className="orderInfoCard-heading">進行中</p>
              </Card>
            </Box>
          </CustomTabPanel>
          <CustomTabPanel value={tabValue} index={1}>
            {pendingPickOrderLoading ? <Loading /> : <OrderTable orders={pendingPickOrder} />}
          </CustomTabPanel>
          <CustomTabPanel value={tabValue} index={2}>
            {pickedUpOrderLoading ? <Loading /> : <OrderTable orders={pickedUpOrder} />}
          </CustomTabPanel>
          <CustomTabPanel value={tabValue} index={3}>
            {waitingNroOrderLoading ? <Loading /> : <OrderTable orders={waitingNroOrder} />}
          </CustomTabPanel>
          <CustomTabPanel value={tabValue} index={4}>
            {completeNotVerifyOrderLoading ? <Loading /> : <OrderTable orders={completeNotVerifyOrder} />}
          </CustomTabPanel>
          <CustomTabPanel value={tabValue} index={5}>
            {inProgressOrderLoading ? <Loading /> : <OrderTable orders={inProgressOrder} />}
          </CustomTabPanel>
        </Box>
      </div>
    </div>
  );
};

export default IntegratedDashboard;
