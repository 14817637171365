import React from 'react';
import {
  CardActions,
  ListButton,
  RefreshButton,
  Show,
  ReferenceManyField,
  Tab,
  ReferenceField,
  TabbedShowLayout,
  TextField,
  Datagrid,
  EditButton,
  DeleteButton,
  DateField,
  Button,
  Link,
  ImageField,
  FunctionField,
  SimpleShowLayout,
  ArrayField,
  useShowController,
  ReferenceArrayField,
  ChipField,
  SingleFieldList,
  useRedirect,
  TabbedShowLayoutTabs,
} from 'react-admin';
import BuildIcon from '@material-ui/icons/Build';
import PostAddIcon from '@material-ui/icons/PostAdd';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import BooleanNumField from 'components/BooleanNumField';
import Card from '@material-ui/core/Card';
import { Button as MuiButton, Tabs } from '@material-ui/core';
import VoidOrderModal from './VoidOrderModal';
import UpdateAssignedWorkerModal from './UpdateAssignedWorkerModal';
import { convertLocaleDate } from 'helpers/localeDate';
import OrderPayment from './OrderPayment';
import myAxios from 'helpers/axios';
import StatementDateModal from './StatementDateModal';
import UpdateCheckingReportModal from './partial/UpdateCheckingReportModal';
import UploadOrderResultItemPicModal from './partial/UploadOrderResultItemPicModal';
import DeleteOrderResultItemPicButton from './partial/DeleteOrderResultItemPicButton';
import WorkerStatementDateModal from './WorkerStatementDateModal';
import VerifyButton from './VerifyButton';
import CustomOrderItemDelete from './partial/CustomOrderItemDelete';

const AddNewOrderItemButton = ({ record }) => {
  return (
    <Button
      component={Link}
      to={{
        pathname: '/admin/order-items/create',
        // Here we specify the initial record for the create view
        state: {
          record: {
            order_id: record?.id,
            company_id: record?.company_id,
            category_code: record?.order_cat_type,
            companyName: record?.company?.name,
            categoryName: record?.orderCatType.name,
          },
        },
      }}
      label="加入新訂單項目"
    >
      <PostAddIcon />
    </Button>
  );
};

const AddNewWorkerJobItemButton = ({ record }) => {
  return (
    <Button
      component={Link}
      to={{
        pathname: '/admin/worker-job-items/create',
        // Here we specify the initial record for the create view
        state: { record: { job_id: record?.job_id } },
      }}
      label="加入新工程項目"
    >
      <PostAddIcon />
    </Button>
  );
};

const AddDefectOrderButton = ({ record }) => {
  return (
    <Button
      component={Link}
      to={{
        pathname: '/admin/defect-orders/create',
        // Here we specify the initial record for the create view
        state: { record: { order_id: record?.id, order_cat_type: record?.order_cat_type } },
      }}
      label="建立執漏單"
    >
      <BuildIcon />
    </Button>
  );
};

const OrderShowActions = ({ basePath, data }) => {
  return (
    <CardActions style={{ justifyContent: 'flex-start' }}>
      <ListButton basePath={basePath} />
      <RefreshButton />
      <AddNewOrderItemButton record={data} />
      <AddNewWorkerJobItemButton record={data} />
      {data?.defect_order_id ? <div>[已建立執漏單]</div> : <AddDefectOrderButton record={data} />}
      {data?.status != 'completed' ? <UpdateAssignedWorkerModal record={data} /> : null}
      {/* {
        data?.job_id || (data?.status !== 'picked_up' && data?.status !== 'pending_pick_up') ?
          (
            data?.status == 'void' ?
              <div>[訂單已取消]</div>
              :
              <div>[訂單不能取消]</div>) :
          <VoidOrderModal record={data} />
      } */}
      <VoidOrderModal record={data} />
      <StatementDateModal record={data} />
      <WorkerStatementDateModal record={data} />
      <VerifyButton record={data} />
    </CardActions>
  );
};

const OrderShow = (props) => {
  const { record } = useShowController(props);
  const [downloadPdfError, setDownloadPdfError] = React.useState(null);
  const [downloading, setDownloading] = React.useState(false);
  const redirect = useRedirect();
  const downloadPDF = async (endpoint) => {
    setDownloading(true);
    try {
      myAxios
        .get(myAxios.addApiUrl(endpoint), {
          responseType: 'blob',
        })
        .then((response) => {
          //Create a Blob from the PDF Stream
          const file = new Blob([response.data], { type: 'application/pdf' });
          //Build a URL from the file
          const fileURL = URL.createObjectURL(file);
          const pdfWindow = window.open('about:blank', '_blank');
          //Open the URL on new Window
          pdfWindow.location.href = fileURL;
          setDownloadPdfError(null);
          setDownloading(false);
        })
        .catch((error) => {
          setDownloadPdfError('未能下載文件, 請檢查訂單');
          setDownloading(false);
        });
    } catch (error) {
      return { error };
    }
  };
  return (
    <Show actions={<OrderShowActions />} {...props}>
      <TabbedShowLayout tabs={<TabbedShowLayoutTabs scrollButtons="auto" variant="scrollable" />}>
        <Tab label="訂單資料">
          <EditButton label="更新" />
          <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
            <MuiButton
              onClick={() => downloadPDF(`/file/pdf/order-invoice?order_id=${record.id}`)}
              disabled={downloading}
              mode="outlined"
              style={{ marginLeft: 0, marginRight: 10, backgroundColor: '#fff' }}
            >
              <PictureAsPdfIcon /> 下載發票
            </MuiButton>
            <MuiButton
              onClick={() => downloadPDF(`/file/pdf/order-receipt?order_id=${record.id}`)}
              disabled={downloading}
              mode="outlined"
              style={{ marginLeft: 10, marginRight: 10, backgroundColor: '#fff' }}
            >
              <PictureAsPdfIcon /> 下載收據
            </MuiButton>
            {downloadPdfError ? downloadPdfError : null}
          </div>
          <TextField source="ref" label="訂單參考編號" />
          <ReferenceArrayField label="分店標籤" reference="admin/tags" source="tagIds">
            <SingleFieldList linkType="show">
              <ChipField source="label" />
            </SingleFieldList>
          </ReferenceArrayField>
          <ReferenceManyField label="訂單日誌" reference="admin/order/order-history" target="order_id">
            <Datagrid>
              <TextField source="status_code" label="Status" />
              <TextField source="statusChanger" label="負責人" />
              <FunctionField
                label="時間"
                render={(record) => {
                  const date = new Date(`${record.created_at}`);
                  return <div>{convertLocaleDate(date)}</div>;
                }}
              />
            </Datagrid>
          </ReferenceManyField>
          <FunctionField
            label="訂單建立時間"
            render={(record) => {
              const date = new Date(`${record.created_at}`);
              return <div>{convertLocaleDate(date)}</div>;
            }}
          />
          <FunctionField
            label="預約時間"
            render={(record) => {
              const date = new Date(`${record.appointment_date}T${record.appointment_time}Z`);
              const dateStr = date.toLocaleString();
              return <div>{dateStr}</div>;
            }}
          />
          <BooleanNumField source="allow_dispatch" label="派送至師傅APP" />
          <BooleanNumField source="is_emergency" label="急單" />
          {/*<FunctionField label="訂單創立者"
            render={record => {
              return (
                <>
                  {record.requested_by == null ?
                    `管理員: ${record?.admin?.username}`
                    : `用戶: ${record?.requestedBy?.username}`
                  }
                </>
              )
            }}
          />*/}
          <TextField source="orderCatType.name" label="訂單類別" />
          <ReferenceField label="公司名稱" reference="admin/companies" source="company_id" link="show">
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField label="分店名稱" reference="admin/sites" source="site_id" link="show">
            <TextField source="name" />
          </ReferenceField>
          <TextField source="total_price" label="訂單總價" />
          <TextField source="remark" label="訂單備註欄" />
          <ImageField label="訂單備註圖片" source="orderRemarkImages" src="staticServePath" />
          <TextField source="internal_remark" label="訂單內部備註欄" />
        </Tab>
        <Tab label="訂單詳細內容" path="items">
          <ReferenceManyField label="" reference="admin/order-items" target="order_id">
            <Datagrid>
              <TextField source="siteServiceItemName" label="項目名稱" />
              <TextField source="qty" label="數量" />
              <TextField source="price" label="價錢 (單價)" />
              <TextField source="status" label="Status" />
              <EditButton label="更新" />
              <DeleteButton redirect={false} label="刪除" />
              <CustomOrderItemDelete />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
        <Tab label="師傅工程詳細內容" path="job">
          <ReferenceField addLabel={false} reference="admin/worker-jobs" source="job_id" link="show">
            <SimpleShowLayout>
              <TextField source="status" label="工程Status" />
              <Card
                variant="outlined"
                style={{ maxWidth: '500px', display: 'flex', border: '1px solid', padding: '5px' }}
              >
                <div style={{ flexBasis: '50%' }}>
                  <ImageField source="workerProfilePic.data" src="data" />
                </div>
                <div style={{ flexBasis: '50%', minWidth: '100px', display: 'flex', flexDirection: 'column' }}>
                  <p style={{ fontSize: '6px', color: '#ccc' }}>師傅編號</p>
                  <TextField label="師傅編號" source="workerProfile.wid" />
                  <p style={{ fontSize: '6px', color: '#ccc' }}>姓名/用戶名稱</p>
                  <FunctionField
                    label="姓名"
                    render={(record) =>
                      `${record?.workerProfile?.first_name} ${record?.workerProfile?.last_name} / ${record?.workerUser}`
                    }
                  />
                  <p style={{ fontSize: '6px', color: '#ccc' }}>性別</p>
                  <FunctionField label="姓別" render={(record) => (record.workerProfile.sex == 'm' ? '男' : '女')} />
                  <p style={{ fontSize: '6px', color: '#ccc' }}>聯絡電話</p>
                  <TextField source="workerProfile.contact_number" label="聯絡電話" />
                </div>
              </Card>
              <p>工程詳細項目</p>
              <ReferenceManyField reference="admin/worker-job-items" target="job_id">
                <Datagrid>
                  <TextField label="項目名稱" source="workerServiceItemName" />
                  <TextField label="數量" source="qty" />
                  <TextField label="Unit Cost" source="price" />
                  <TextField label="Status" source="statusName" />
                  <DateField showTime={true} label="建立時間" source="created_at" />
                  <EditButton />
                  <DeleteButton redirect={false} />
                </Datagrid>
              </ReferenceManyField>
            </SimpleShowLayout>
          </ReferenceField>
        </Tab>
        <Tab label="工程完工照片及報告" path="result-pics">
          <UpdateCheckingReportModal record={record} />
          <TextField source="checking_report" label="檢查報告" style={{ flex: 1 }} />
          <TextField source="complete_report" label="完工備註" emptyText="未輸入" />
          <ArrayField source="details" label="完工相片">
            <Datagrid>
              <TextField label="項目名稱" source="siteServiceItemName" fullWidth />
              <FunctionField
                render={(itemRecord) => {
                  if (itemRecord.resultPics == null) {
                    return '未上載';
                  }
                  return (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                      }}
                    >
                      {itemRecord.resultPics.map((pic, index) => {
                        return (
                          <div
                            style={{
                              position: 'relative',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              margin: '5px',
                            }}
                          >
                            <a href={pic.staticServePath} target="_blank">
                              <img key={index} src={pic.staticServePath} style={{ width: '100px', height: '100px' }} />
                            </a>
                            <DeleteOrderResultItemPicButton id={pic.id} itemName={itemRecord.siteServiceItemName} />
                          </div>
                        );
                      })}
                    </div>
                  );
                }}
              />
              <FunctionField
                render={(itemRecord) => {
                  return (
                    <UploadOrderResultItemPicModal
                      orderId={record.id}
                      orderItemId={itemRecord.id}
                      orderItemName={itemRecord.siteServiceItemName}
                    />
                  );
                }}
              />
            </Datagrid>
          </ArrayField>
        </Tab>
        <Tab label="收付款記錄" path="payments">
          <OrderPayment record={props?.record} />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default OrderShow;
