import myAxios from 'helpers/axios';
import _ from 'lodash';
import React, { useMemo } from 'react';
import ContentCreate from '@material-ui/icons/Create';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import {
  Show,
  SimpleShowLayout,
  TextField,
  FunctionField,
  CardActions,
  ListButton,
  RefreshButton,
  useRecordContext,
  useGetOne,
  linkToRecord,
  Link,
  ReferenceField,
  ReferenceArrayField,
  Datagrid,
  EditButton,
  useShowController,
  ShowButton,
  BooleanField,
  List,
  useQueryWithStore,
  useGetList,
  useRefresh,
  useNotify,
} from 'react-admin';
import { Button, Checkbox, FormControlLabel, CircularProgress } from '@material-ui/core';
import axios from 'axios';

const CompanyStatementShowActions = ({ basePath, data }) => {
  return (
    <CardActions>
      <ListButton basePath={basePath} />
      <RefreshButton />
    </CardActions>
  );
};

const CompanyStatementShow = (props) => {
  const [withResultImage, setWithResultImage] = React.useState(true);
  const refresh = useRefresh();
  const notify = useNotify();
  const { record } = useShowController(props);
  const [downloading, setDownloading] = React.useState(false);
  const [sending, setSending] = React.useState(false);
  const downloadPdf = async (page = null) => {
    setDownloading(true);
    console.log(page);
    const params = new URLSearchParams();
    params.append('statement_id', record.id);
    if (withResultImage === true) {
      params.append('show_result_images', 1);
    }
    if (page) {
      params.append('pageSize', 15);
      params.append('page', page);
    }
    const reqUrl = myAxios.addApiUrl(`/file/pdf/statement?${params.toString()}`);
    try {
      myAxios
        .get(reqUrl, {
          responseType: 'blob',
        })
        .then((response) => {
          //Create a Blob from the PDF Stream
          const file = new Blob([response.data], { type: 'application/pdf' });
          //Build a URL from the file
          const fileURL = URL.createObjectURL(file);
          //Open the URL on new Window
          const pdfWindow = window.open();
          pdfWindow.location.href = fileURL;
          setDownloading(false);
        })
        .catch((error) => {
          console.log(error);
          setDownloading(false);
        });
    } catch (error) {
      return { error };
    }
  };
  const handleSetWithResultImageChange = React.useCallback(() => {
    setWithResultImage((prev) => !prev);
  }, []);

  const { ids, data, loading, error, loaded, total } = useGetList(
    'admin/worker-statements',
    { page: 1, perPage: 50 },
    { field: 'created_at', order: 'desc' },
    {
      from_date: record.from_date,
      to_date: record?.to_date,
    }
  );

  const onGenerateWorkerStatement = React.useCallback(async () => {
    try {
      await myAxios
        .post(
          myAxios.addApiUrl(`/admin/worker-statement/generate-by-company-statement?companyStatementId=${record?.id}`)
        )
        .then(() => {
          refresh();
        });
    } catch (error) {
      return { error };
    }
  }, [record?.id, refresh]);

  const downloadWorkerPdf = async (statementId) => {
    setDownloading(true);
    try {
      myAxios
        .get(myAxios.addApiUrl(`/file/pdf/worker-statement?statement_id=${statementId}`), {
          responseType: 'blob',
        })
        .then((response) => {
          //Create a Blob from the PDF Stream
          const file = new Blob([response.data], { type: 'application/pdf' });
          //Build a URL from the file
          const fileURL = URL.createObjectURL(file);
          //Open the URL on new Window
          const pdfWindow = window.open();
          pdfWindow.location.href = fileURL;
          setDownloading(false);
        })
        .catch((error) => {
          console.log(error);
          setDownloading(false);
        });
    } catch (error) {
      return { error };
    }
  };

  const sendWorkerPdfWhatsapp = async (statementId) => {
    setSending(true);
    return await myAxios
      .get(myAxios.addApiUrl(`/admin/worker-statement/send-statement-to-whatsapp?statementId=${statementId}`))
      .then((response) => {
        setSending(false);
        notify('已發送至師傅Whatsapp');
      })
      .catch((error) => {
        console.log(error);
        setSending(false);
      });
  };

  return (
    <>
      <Show actions={<CompanyStatementShowActions />} {...props}>
        <SimpleShowLayout>
          <ReferenceField label="公司" source="company_id" reference="admin/companies" link="show">
            <TextField source="name" />
          </ReferenceField>
          <FunctionField
            label="日期"
            render={(record) => {
              return (
                <div>
                  {record?.from_date} - {record?.to_date}
                </div>
              );
            }}
          />
          <TextField source="ref" label="Ref" />
          <FunctionField
            label="狀態"
            render={(record) => {
              if (record.status === 'generated') {
                return <div>核對中 - {record?.generated_at}</div>;
              }
              if (record.status === 'issued') {
                return <div>發佈中 - {record?.issued_at}</div>;
              }
              if (record.status === 'settled') {
                return (
                  <div>
                    <div>已結算 - {record?.settled_at}</div>
                    <a href={record?.statementImg?.src} target="_blank" rel="noreferrer">
                      <img src={record?.statementImg?.src} alt="statement" style={{ maxWidth: 150 }} />
                    </a>
                  </div>
                );
              }
            }}
          />

          <ReferenceArrayField source="orderIds" label="訂單" reference="admin/orders">
            <Datagrid empty="沒有訂單">
              <TextField source="ref" label="訂單編號" />
              <ReferenceField source="site_id" label="分店" reference="admin/sites">
                <TextField source="name" />
              </ReferenceField>
              <FunctionField
                label="應收款項"
                render={(record) => {
                  return <div>{_.sumBy(record.orderPaymentStatus, (o) => o.total)}</div>;
                }}
              />
              <FunctionField
                label="尚欠款項"
                render={(record) => {
                  return <div>{_.sumBy(record.orderPaymentStatus, (o) => o.remain)}</div>;
                }}
              />
              <TextField source="company_statement_date" label="日期" />
              <ShowButton />
            </Datagrid>
          </ReferenceArrayField>
          <ReferenceField source="discount_id" label="折扣名稱" reference="admin/discount-rates">
            <TextField source="name" />
          </ReferenceField>
          <TextField source="discount_rate" label="折扣(%)" />
          <FunctionField
            label="應收款項"
            render={(record) => {
              return <div>{record.is_used_discount == 1 ? '已套用折扣' : '未有套用折扣'}</div>;
            }}
          />
          <EditButton label="更新月結單" />

          <CardActions>
            <FormControlLabel
              control={<Checkbox checked={withResultImage} onChange={handleSetWithResultImageChange} />}
              label="插入完工照片"
            />
            {withResultImage ? (
              <>
                {Array.from({ length: Math.ceil(record.orderIds.length / 15) }).map((_value, index) => {
                  return (
                    <Button
                      onClick={() => downloadPdf(index + 1)}
                      disabled={downloading}
                      mode="contained"
                      style={{ backgroundColor: '#fff', color: '#333' }}
                    >
                      {downloading ? (
                        <CircularProgress />
                      ) : (
                        <>
                          <PictureAsPdfIcon />
                          查看月結單 ({index + 1})
                        </>
                      )}
                    </Button>
                  );
                })}
              </>
            ) : (
              <Button
                onClick={() => downloadPdf(null)}
                disabled={downloading}
                mode="contained"
                style={{ backgroundColor: '#fff', color: '#333' }}
              >
                {downloading ? (
                  <CircularProgress />
                ) : (
                  <>
                    <PictureAsPdfIcon />
                    查看月結單(全部)
                  </>
                )}
              </Button>
            )}
          </CardActions>
        </SimpleShowLayout>
      </Show>
      <div style={{ marginTop: 20 }}>
        <p style={{ fontSize: 24, fontWeight: 'bold' }}>相關師傅月結單</p>
        <Button
          onClick={onGenerateWorkerStatement}
          variant="contained"
          color="primary"
          style={{ marginLeft: 10, marginRight: 10 }}
        >
          生成相關師傅月結單
        </Button>
        {/* <Button variant="contained" color="primary" style={{ marginLeft: 10, marginRight: 10 }}>
            發送月結單至師傅whatsapp
          </Button> */}
        <Datagrid
          empty="沒有師傅月結單"
          basePath="admin/worker-statements"
          currentSort={{ field: 'created_at', order: 'desc' }}
          data={data}
          ids={ids}
          selectedIds={[]}
          loaded={loaded}
          total={total}
          setSort={() => {
            console.log('set sort');
          }}
          onSelect={() => {
            console.log('on select');
          }}
          onToggleItem={() => {
            console.log('on toggle item');
          }}
        >
          <TextField source="ref" label="Ref" />
          <ReferenceField label="師傅" source="worker_id" reference="admin/worker-users" link="show">
            <TextField source="full_name" />
          </ReferenceField>
          <FunctionField
            label="日期"
            render={(record) => {
              return (
                <div>
                  {record.from_date}至{record.to_date}
                </div>
              );
            }}
          />
          <FunctionField
            label="平台費"
            render={(record) => {
              return <div>{record.fee}%</div>;
            }}
          />
          <FunctionField
            label="狀態"
            render={(record) => {
              if (record.status === 'generated') {
                return <div>核對中 - {record?.generated_at}</div>;
              }
              if (record.status === 'issued') {
                return <div>發佈中 - {record?.issued_at}</div>;
              }
              if (record.status === 'settled') {
                return <div>已結算 - {record?.settled_at}</div>;
              }
            }}
          />
          <FunctionField
            label="詳情"
            render={(record) => <Link to={`/admin/worker-statements/${record.id}`}>詳情</Link>}
          />
          <FunctionField
            label=""
            render={(record) => {
              return (
                <div style={{ gap: '4px' }}>
                  <Button variant="contained" onClick={() => downloadWorkerPdf(record?.id)} disabled={downloading}>
                    下載
                  </Button>
                  <Button variant="contained" onClick={() => sendWorkerPdfWhatsapp(record?.id)} disabled={sending}>
                    發送Whatsapp
                  </Button>
                </div>
              );
            }}
          />
        </Datagrid>
      </div>
    </>
  );
};

export default CompanyStatementShow;
