import React, { Fragment, cloneElement } from 'react';
import * as _ from 'lodash';
import {
  ReferenceField,
  Button,
  List,
  useListContext,
  useShowController,
  sanitizeListRestProps,
  Datagrid,
  TextField,
  Show,
  Create,
  Edit,
  SimpleForm,
  TextInput,
  Filter,
  BulkDeleteButton,
  Toolbar,
  TopToolbar,
  CreateButton,
  ListButton,
  RefreshButton,
  SaveButton,
  DeleteButton,
  ShowButton,
  EditButton,
  required,
  EmailField,
  ReferenceManyField,
  TabbedShowLayout,
  Tab,
  FunctionField,
  Link,
  BooleanInput,
  useQuery,
  Loading,
  useNotify,
  useRefresh,
  useRecordContext,
  ArrayField,
  SingleFieldList,
  ChipField,
  ReferenceArrayField,
} from 'react-admin';
import CardActions from '@material-ui/core/CardActions';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import Modal from '@material-ui/core/Modal';
import Chip from '@material-ui/core/Chip';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import DeleteIcon from '@material-ui/icons/Delete';
import BooleanNumField from 'components/BooleanNumField';
import { makeStyles } from '@material-ui/core/styles';
import RemoteSelectInput from 'components/RemoteSelectInput';
import RemoteAutocompleteInput from 'components/RemoteAutocompleteInput';
import SubCategoryRemoteSelectInput from 'components/SubCategoryRemoteSelectInput';
import myAxios from 'helpers/axios';
import CompanyServiceItemLogButton from './CompanyServiceItemLogButton';
import { convertLocaleDate } from 'helpers/localeDate';
import ImportBatchItemButton from './ImportBatchItemButton';
import PostAddIcon from '@material-ui/icons/PostAdd';
import HomeIcon from '@material-ui/icons/Home';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import AddNewSiteWhatsappButton from './AddNewSiteWhatsappButton';
import Config from 'Config';
import CompanyServiceItemPdf from './CompanyServiceItemPdf';
const useStyles = makeStyles({
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
});

const CompanyListActions = (props) => {
  const { className, exporter, filters, maxResults, ...rest } = props;
  const { resource, displayedFilters, filterValues, basePath, showFilter } = useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      <CreateButton basePath={basePath} label="新增" />
    </TopToolbar>
  );
};

const CompanyBulkActionButtons = (props) => (
  <Fragment>
    <BulkDeleteButton undoable={false} {...props} />
  </Fragment>
);

const CompanyEditToolbar = (props) => (
  <Toolbar {...props} classes={useStyles()}>
    <SaveButton label="儲存" />
    <DeleteButton label="刪除" undoable={false} />
  </Toolbar>
);

const CompanyForm = (props) => (
  <SimpleForm {...props}>
    <TextInput source="name" label="公司名稱" validate={required()} fullWidth />
    <TextInput source="br" label="BR" validate={required()} fullWidth />
    <RemoteSelectInput
      source="business_nature"
      label="業務性質"
      remoteurl="/value/business-natures"
      idproperty="id"
      valueproperty="name"
      validate={required()}
      alwaysOn
    />
    <TextInput source="contact_number" label="聯絡電話" fullWidth />
    <TextInput source="email" label="Email" fullWidth />
    <TextInput source="billing_company_name" label="帳單公司名稱" />
    <TextInput source="billing_address" label="帳單地址" />
    <TextInput source="billing_contact_person" label="帳單聯絡人" />
    <TextInput source="billing_contact_number" label="帳單聯絡電話" />
    <TextInput source="billing_contact_department" label="帳單負責部門" />
    <BooleanInput source="is_completion_paper_needed" label="完工是否需要完工紙" />
    <BooleanInput source="active" label="Active" fullWidth />
  </SimpleForm>
);

export const CompanyList = (props) => (
  <List {...props} actions={<CompanyListActions />} bulkActionButtons={<CompanyBulkActionButtons />}>
    <Datagrid rowClick="show">
      <TextField source="name" />
      <ReferenceField source="business_nature" reference="code/business-natures" label="公司業務">
        <TextField source="name" />
      </ReferenceField>
      <EmailField source="email" />
      <BooleanNumField source="active" label="Active" />
      <FunctionField
        label="建立日期"
        render={(record) => {
          const date = new Date(`${record.created_at}`);
          return <div>{convertLocaleDate(date)}</div>;
        }}
      />
      <FunctionField
        label="更新日期"
        render={(record) => {
          const date = new Date(`${record.updated_at}`);
          return <div>{convertLocaleDate(date)}</div>;
        }}
      />
      <ShowButton label="詳情" />
      <EditButton label="編輯" />
    </Datagrid>
  </List>
);

const AddNewCompanyServiceItemButton = ({ record }) => (
  <Button
    component={Link}
    to={{
      pathname: '/admin/company-service-items/create',
      // Here we specify the initial record for the create view
      state: { record: { company_id: record?.id } },
    }}
    label="加入新報價項目"
  >
    <PostAddIcon />
  </Button>
);

const AddNewCompanySiteButton = ({ record }) => (
  <Button
    component={Link}
    to={{
      pathname: '/admin/sites/create',
      // Here we specify the initial record for the create view
      state: { record: { company_id: record?.id } },
    }}
    label="加入分店"
  >
    <HomeIcon />
  </Button>
);

const AddNewCompanySiteUserButton = ({ record }) => {
  return (
    <Button
      component={Link}
      to={{
        pathname: '/admin/site-users/create',
        // Here we specify the initial record for the create view
        state: { record: { company_id: record?.id } },
      }}
      label="加入分店用戶"
    >
      <PersonAddIcon />
    </Button>
  );
};

const CompanyShowActions = ({ basePath, data }) => (
  <CardActions>
    <ListButton basePath={basePath} />
    <RefreshButton />
    <AddNewCompanyServiceItemButton record={data} />
    <AddNewCompanySiteButton record={data} />
    <AddNewCompanySiteUserButton record={data} />
    {/* <AddNewSiteWhatsappButton record={data} /> */}
    <ImportBatchItemButton record={data} />
  </CardActions>
);

const CompanyShowCompanyServiceItemFilter = (props) => {
  return (
    <Filter {...props}>
      <RemoteAutocompleteInput
        source="category_code"
        label="維修類別"
        remoteurl="/value/service-item-category"
        idproperty="code"
        valueproperty="name"
        alwaysOn
      />
      <SubCategoryRemoteSelectInput
        source="sub_category_code"
        label="維修子類別"
        remoteurl="/value/service-item-sub-category"
        idproperty="code"
        valueproperty="name"
        alwaysOn
      />
      <TextInput source="q" label="名稱" alwaysOn />
    </Filter>
  );
};

const SiteRecipientAside = ({ record }) => {
  const {
    data: recipientsData,
    loading: recipientsLoading,
    error: recipientsError,
  } = useQuery({
    type: 'getList',
    resource: 'admin/site-recipients',
    payload: {
      pagination: { page: 1, perPage: 200 },
      sort: { field: 'site_id', order: 'desc' },
      filter: { company_id: record?.id },
    },
  });
  const {
    data: whatsappData,
    loading: whatsappLoading,
    error: whatsappError,
  } = useQuery({
    type: 'getList',
    resource: 'admin/site-whatsapps',
    payload: {
      pagination: { page: 1, perPage: 200 },
      sort: { field: 'site_id', order: 'desc' },
      filter: { company_id: record?.id },
    },
  });
  const [whatsappGroupOption, setWhatsappGroupOption] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [selectedWhatsappGroup, setSelectedWhatsappGroup] = React.useState({
    groupLabel: '',
    groupRecord: {},
  });
  const openConfirmDeleteModal = () => setOpen(true);
  const dismissConfirmDeleteModal = () => setOpen(false);
  const notify = useNotify();
  const refresh = useRefresh();
  const transformedRecipientsData = _.groupBy(recipientsData, (i) => i.email);
  const transformedWhatsappsData = _.groupBy(whatsappData, (i) => i.receiver);
  const handleDelete = React.useCallback(
    async (id) => {
      await myAxios.delete(myAxios.addApiUrl(`/admin/site-recipients/${id}`)).then((resp) => console.log(resp));
      notify(`已刪除聯絡電郵`);
      refresh();
    },
    [notify, refresh]
  );

  const handleWhatsappDelete = React.useCallback(
    async (id) => {
      await myAxios.delete(myAxios.addApiUrl(`/admin/site-whatsapps/${id}`)).then((resp) => console.log(resp));
      notify(`已刪除Whatsapp`);
      refresh();
    },
    [notify, refresh]
  );

  const onBatchDelete = async () => {
    const ids = Object.values(selectedWhatsappGroup?.groupRecord)
      .map((site) => site.map((record) => record.id))
      .flat();
    await myAxios
      .delete(myAxios.addApiUrl(`/admin/site-whatsapp/batch-delete?ids=${ids.join(',')}`))
      .then((resp) => console.log(resp));
    notify(`已批量刪除Whatsapp`);
    refresh();
  };

  React.useEffect(() => {
    var urlencoded = new URLSearchParams();
    urlencoded.append('token', Config.ultraMsgToken);
    myAxios
      .get(`https://api.ultramsg.com/${Config.ultraMsgInstanceId}/groups?${urlencoded}`)
      .then((result) => setWhatsappGroupOption(result.data));
  }, []);
  return (
    <div stlye={{ display: 'flex', flexDirection: 'column' }}>
      <div
        style={{ width: '100%', maxWidth: '500px', margin: '1em', marginTop: 0, maxHeight: '500px', overflowY: 'auto' }}
      >
        <Typography variant="h6">分店通知聯絡電郵</Typography>
        <Button
          component={Link}
          to={{
            pathname: '/admin/site-recipients/create',
            // Here we specify the initial record for the create view
            state: { record: { company_id: record?.id } },
          }}
          label="加入新聯絡人"
        >
          <ChatBubbleIcon />
        </Button>
        <Divider style={{ marginBottom: '10px' }} />
        <Typography variant="body2">
          {!recipientsLoading && !recipientsError ? (
            Object.entries(transformedRecipientsData).map((row) => {
              const grouped = _.groupBy(row[1], (b) => b.site.name);
              return (
                <>
                  <Box>
                    <Typography style={{ fontWeight: '600', marginBottom: '5px' }}>{row[0]}</Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%', flexWrap: 'wrap' }}>
                      {Object.entries(grouped).map((row) => {
                        return (
                          <>
                            {row[1].map((type) => (
                              <Chip
                                key={type.id}
                                variant="outlined"
                                label={`${type?.site?.name} - ${type?.messageTypeName}`}
                                onDelete={() => handleDelete(type.id)}
                              />
                            ))}
                          </>
                        );
                      })}
                    </Box>
                  </Box>
                  <Divider style={{ marginTop: '10px', marginBottom: '10px' }} />
                </>
              );
            })
          ) : (
            <Loading />
          )}
        </Typography>
      </div>
      <div
        style={{ width: '100%', maxWidth: '500px', margin: '1em', marginTop: 0, maxHeight: '500px', overflowY: 'auto' }}
      >
        <Typography variant="h6">分店通知Whatsapp</Typography>
        <AddNewSiteWhatsappButton record={record} />
        <Divider style={{ marginBottom: '10px' }} />
        <Typography variant="body2">
          {!whatsappLoading && !whatsappError ? (
            Object.entries(transformedWhatsappsData).map((row) => {
              const grouped = _.groupBy(row[1], (b) => b.site_id);
              const groupLabel = row[0]?.includes('@')
                ? whatsappGroupOption?.find((r) => r.id == row[0])?.name
                : row[0];
              return (
                <>
                  <Box>
                    <Typography style={{ fontWeight: '600', marginBottom: '5px' }}>
                      {groupLabel}
                      <Button
                        onClick={() => {
                          openConfirmDeleteModal();
                          setSelectedWhatsappGroup({
                            groupLabel: groupLabel,
                            groupRecord: grouped,
                          });
                        }}
                      >
                        <DeleteIcon />
                      </Button>
                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%', flexWrap: 'wrap' }}>
                      {Object.entries(grouped).map((row) => {
                        return (
                          <>
                            {row[1].map((type) => (
                              <Chip
                                key={type.id}
                                variant="outlined"
                                label={`${type?.site?.name} - ${type?.whatsappType.name}`}
                                onDelete={() => handleWhatsappDelete(type.id)}
                              />
                            ))}
                          </>
                        );
                      })}
                    </Box>
                  </Box>
                  <Divider style={{ marginTop: '10px', marginBottom: '10px' }} />
                </>
              );
            })
          ) : (
            <Loading />
          )}
        </Typography>
        <Modal
          open={open}
          onClose={dismissConfirmDeleteModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              backgroundColor: '#fff',
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 400,
              p: 4,
            }}
          >
            <Typography id="modal-modal-title" variant="h6" component="h2">
              確認刪除 {selectedWhatsappGroup.groupLabel} 的分店通知Whatsapp?
              {Object.entries(selectedWhatsappGroup?.groupRecord).map((row) => {
                return (
                  <>
                    {row[1].map((type) => (
                      <Chip
                        key={type.id}
                        variant="outlined"
                        label={`${type?.site?.name} - ${type?.whatsappType.name}`}
                      />
                    ))}
                  </>
                );
              })}
            </Typography>
            <Divider style={{ marginTop: 10, marginBottom: 10 }} />
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              <Button onClick={dismissConfirmDeleteModal}>
                <Typography>取消</Typography>
              </Button>
              <Button variant="contained" onClick={onBatchDelete}>
                <Typography>確認</Typography>
              </Button>
            </Typography>
          </Box>
        </Modal>
      </div>
    </div>
  );
};

const CompanyServiceItemListActions = (props) => {
  const record = useRecordContext(props);
  return (
    <TopToolbar>
      <CompanyServiceItemLogButton companyId={record.id} />
      <CompanyServiceItemPdf companyId={record.id} />
    </TopToolbar>
  );
};

export const CompanyShow = (props) => {
  const { record } = useShowController(props);
  return (
    <Show actions={<CompanyShowActions />} {...props}>
      <TabbedShowLayout>
        <Tab label="公司資料">
          <EditButton />
          <TextField source="name" label="公司名稱" style={{ width: '50%' }} />
          <TextField source="br" label="商業登記號碼" style={{ width: '50%' }} />
          <ReferenceField label="業務性質" source="business_nature" reference="code/business-natures">
            <TextField source="name" />
          </ReferenceField>
          <TextField source="contact_number" label="聯絡電話" />
          <TextField source="email" label="Email" />
          <TextField source="billing_company_name" label="帳單公司名稱" />
          <TextField source="billing_address" label="帳單地址" />
          <TextField source="billing_contact_person" label="帳單聯絡人" />
          <TextField source="billing_contact_number" label="帳單聯絡電話" />
          <TextField source="billing_contact_department" label="帳單負責部門" />
        </Tab>
        <Tab label="分店" path="sites">
          <ReferenceManyField label="分店" reference="admin/sites" target="company_id">
            <List
              filterDefaultValues={{ company_id: record?.id }}
              actions={false}
              exporter={false}
              aside={<SiteRecipientAside record={record} />}
            >
              <Datagrid>
                <TextField source="name" label="分店名稱" />
                <TextField source="address" label="分店地址" />
                <TextField source="contact" label="分店地址" />
                <TextField source="business_hour" label="分店地址" />
                <ShowButton />
                <EditButton />
              </Datagrid>
            </List>
          </ReferenceManyField>
        </Tab>
        <Tab label="價目表" path="service-items">
          <ReferenceManyField
            label="維修項目"
            reference="admin/company-service-items"
            target="company_id"
            sort={{ field: 'itemCat', order: 'DESC' }}
          >
            <List
              filters={<CompanyShowCompanyServiceItemFilter />}
              filterDefaultValues={{ company_id: record?.id }}
              actions={<CompanyServiceItemListActions />}
              hasCreate={false}
              exporter={false}
            >
              <Datagrid>
                <TextField source="item.category.name" label="維修類別" />
                <TextField source="item.subCategory.name" label="維修子類別" />
                <ReferenceField source="item_id" reference="code/service-items" label="維修項目">
                  <TextField source="name" />
                </ReferenceField>
                <TextField source="charge_price" label="價錢" />
                <BooleanNumField source="active" label="Active" />
                <FunctionField
                  label="項目類別"
                  render={(record) => {
                    return <>{record.type == 1 ? '預設項目' : '後加項目'}</>;
                  }}
                />
                <FunctionField
                  label="建立日期"
                  render={(record) => {
                    const date = new Date(`${record.created_at}`);
                    return <div>{convertLocaleDate(date)}</div>;
                  }}
                />
                <FunctionField
                  label="更新日期"
                  render={(record) => {
                    const date = new Date(`${record.updated_at}`);
                    return <div>{convertLocaleDate(date)}</div>;
                  }}
                />
                <EditButton />
              </Datagrid>
            </List>
          </ReferenceManyField>
        </Tab>
        <Tab label="帳戶資料">
          <ReferenceManyField label="用戶" reference="admin/site-users" target="company_id">
            <Datagrid>
              <TextField source="username" label="帳戶名稱" />
              <TextField source="full_name" label="用戶名稱" />
              <FunctionField label="認證帳戶" render={(record) => (record.is_verified === 1 ? '已認證' : '未認證')} />
              <FunctionField label="狀態" render={(record) => (record.status === 10 ? 'Active' : 'Inactive')} />
              <FunctionField
                label="所屬分店"
                render={(record) => {
                  if (record.assignSite.length > 0) {
                    return (
                      <ReferenceArrayField label="所屬分店" source="assignSite" reference="admin/sites">
                        <SingleFieldList>
                          <ChipField source="name" />
                        </SingleFieldList>
                      </ReferenceArrayField>
                    );
                  } else {
                    return <p>所有分店</p>;
                  }
                }}
              />
              <TextField source="email" label="Email" />
              <EditButton />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
        <Tab label="訂單">
          <ReferenceManyField label="訂單記錄" reference="admin/orders" target="company_id">
            <Datagrid>
              <TextField source="ref" label="Ref" />
              <TextField source="orderCatType.name" label="訂單類別" />
              <TextField source="site.name" label="分店名稱" />
              <TextField source="total_price" label="總價" />
              <FunctionField
                label="訂單創立者"
                render={(record) => {
                  return (
                    <>
                      {record.requested_by == null
                        ? `管理員: ${record?.admin?.username}`
                        : `用戶: ${record?.requestedBy?.username}`}
                    </>
                  );
                }}
              />
              <TextField source="total_price" label="訂單總價" />
              <FunctionField
                label="預約時間"
                render={(record) => {
                  const time = record.appointment_time == null ? '' : `- ${record.appointment_time}`;
                  return (
                    <>
                      {record.appointment_date == null && record.appointment_time == null
                        ? '沒有指定日期時間'
                        : `${record.appointment_date} ${time}`}
                    </>
                  );
                }}
              />
              {/*<BooleanNumField source="is_emergency" label="急單"/>
                <BooleanNumField source="allow_dispatch" label="已出公海"/>*/}
              <TextField source="orderStatus.name" label="訂單狀態" />
              <FunctionField
                label="建立日期"
                render={(record) => {
                  console.log(record.created_at);
                  const date = new Date(`${record.created_at}`);
                  return <div>{convertLocaleDate(date)}</div>;
                }}
              />
              <FunctionField
                label="更新日期"
                render={(record) => {
                  const date = new Date(`${record.updated_at}`);
                  return <div>{convertLocaleDate(date)}</div>;
                }}
              />
              <ShowButton />
              <EditButton />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export const CompanyCreate = (props) => (
  <Create {...props}>
    <CompanyForm />
  </Create>
);

export const CompanyEdit = (props) => (
  <Edit {...props} undoable={false}>
    <CompanyForm toolbar={<CompanyEditToolbar />} />
  </Edit>
);
