import React from 'react';
import {
  SimpleForm,
  TextInput,
  ArrayInput,
  required,
  SimpleFormIterator,
  BooleanInput,
  ImageInput,
  ImageField,
  NumberInput,
  RadioButtonGroupInput,
  FormDataConsumer,
  DateInput,
  ReferenceArrayInput,
  SelectArrayInput,
  AutocompleteArrayInput,
  ChipField,
} from 'react-admin';
import RemoteSelectInput from 'components/RemoteSelectInput';
import SiteRemoteSelectInput from 'components/SiteRemoteSelectInput';
import WorkerRemoteSelectInput from 'components/orderItem/WorkerRemoteSelectInput';
import CompanyServiceItemRemoteSelectInput from 'components/orderItem/CompanyServiceItemRemoteSelectInput';

const availableTimeSlot = [
  {
    name: '00:00 至 09:00',
    id: '09:00:00'
  },
  {
    name: '9:00 至 12:00',
    id: '12:00:00'
  },
  {
    name: '12:00 至 14:00',
    id: '14:00:00'
  },
  {
    name: '14:00 至 16:00',
    id: '16:00:00'
  },
  {
    name: '16:00 至 18:00',
    id: '18:00:00'
  },
  {
    name: '18:00 至 21:00',
    id: '21:00:00'
  },
  {
    name: '21:00 至 23:59',
    id: '23:59:00'
  }
];

const OrderEditForm = (props) => {
  return (
    <SimpleForm {...props}>
      <h3>訂單基本資料</h3>
      {/*<RemoteSelectInput
        source="order_cat_type"
        label="訂單類別 - (選擇的訂單類別會影響可選擇的師傅及工程項目價目表)"
        remoteurl="/value/order-cat-type"
        idproperty="code"
        valueproperty="name"
        validate={required()}
        alwaysOn
        fullWidth
      />*/}
      <FormDataConsumer>
        {({formData, ...rest}) => {
        return (
        <ReferenceArrayInput label="分店標籤" reference="admin/tags" source="tagIds" filter={{site_id: formData.site_id}}>
          <AutocompleteArrayInput optionText={`label`}/>
        </ReferenceArrayInput>
        )
        }}
      </FormDataConsumer>
      <RemoteSelectInput
        source="status"
        label="訂單狀態"
        remoteurl="/value/admin-order-status-code"
        idproperty="code"
        valueproperty="name"
        validate={required()}
        alwaysOn
        fullWidth
      />
      {/*<RemoteSelectInput
        source="company_id"
        label="訂單所屬公司 - (選擇的公司會影響工程項目價目表)"
        remoteurl="/value/companies"
        idproperty="id"
        valueproperty="name"
        validate={required()}
        alwaysOn
        fullWidth
      />
      <SiteRemoteSelectInput
        source="site_id"
        label="訂單所屬分店"
        remoteurl="/value/sites"
        idproperty="id"
        valueproperty="name"
        validate={required()}
        alwaysOn
      />*/}
      <DateInput
        source="appointment_date"
        label="預約日期"
      />
      <RadioButtonGroupInput source="appointment_time" label="可供預約時段" choices={availableTimeSlot}
        fullWidth />
      {/*<BooleanInput
        source="is_emergency"
        label="急單? (師傅會於訂單生效時間最遲2小時內到達分店) (急單將會額外收取加急費HKD200)"
        fullWidth
      />
      <BooleanInput
        source="allow_dispatch"
        label="派送至師傅APP"
        fullWidth
      />*/}
      <h3>訂單補充資料</h3>
      <TextInput
        source="remark"
        label="訂單備註欄"
        fullWidth
      />
      <TextInput
        source="internal_remark"
        label="內部用備註欄"
        fullWidth
      />
      {/* <ImageInput
        source="remarkImages"
        label="訂單相片"
        accept="image/*"
        labelMultiple="最多請上載3張圖片 (png, jpg)"
        multiple
        fullWidth
      >
        <ImageField source="src" title="title" />
      </ImageInput> */}
    </SimpleForm>
  )
    ;
}

export default OrderEditForm;