import { Table, TableHead, TableRow, TableCell } from '@material-ui/core';
import { ShowButton } from 'react-admin';

const OrderTable = ({ orders }) => {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>訂單編號</TableCell>
          <TableCell>類別</TableCell>
          {orders && orders?.length && orders?.[0]?.status == ('picked_up' || 'waitingNroOrder') ? (
            <TableCell>接單師傅</TableCell>
          ) : null}
          <TableCell>公司</TableCell>
          <TableCell>分店</TableCell>
          <TableCell>建立時間</TableCell>
          <TableCell></TableCell>
        </TableRow>
        {orders ? (
          orders.map((item, index) => {
            return (
              <TableRow className="pendingPickOrderCard" key={index}>
                <TableCell>{item.ref}</TableCell>
                <TableCell>
                  <p>{item.orderCatType?.name}</p>
                </TableCell>
                {item?.status == ('picked_up' || 'waitingNroOrder') ? (
                  <TableCell>
                    <p>{item.pickupWorkerName}</p>
                  </TableCell>
                ) : null}
                <TableCell>
                  <p>{item.company?.name}</p>
                </TableCell>
                <TableCell>
                  <p>{item.site?.name}</p>
                </TableCell>
                <TableCell>
                  <p>{item.created_at}</p>
                </TableCell>
                <TableCell>
                  <ShowButton basePath="admin/orders" record={item} label="詳細" />
                </TableCell>
              </TableRow>
            );
          })
        ) : (
          <TableRow>
            <TableCell colSpan={6}>沒有訂單</TableCell>
          </TableRow>
        )}
      </TableHead>
    </Table>
  );
};

export default OrderTable;
